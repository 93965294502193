<template>
    <div class="col-md col-12">
        <div class="card card-white mt-2">
            <div class="card-body p-2 ">
                <label
                    class="mb-1 d-flex align-items-center cursor-pointer"
                    :for="speed.id"
                    v-for="speed in speeds"
                    :key="speed.id"
                >
                    <input type="radio" :name="speed.id" :id="speed.id" :value="speed.speed" v-model="chosenSpeed" />
                    <span class="ml-2">{{ speed.description }} ({{ speed.price }} {{ currency }})</span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: { speeds: Array, type: String },
    name: "Sv3Speeds",
    data() {
        return {
            chosenSpeed: "low"
        }
    },
    watch: {
        speeds() {
            this.chosenSpeed = "low"
            this.chosenSpeed = this.defaultSpeedObj.speed
        },
        type() {
            this.chosenSpeed = "low"
        },
        chosenSpeed(value) {
            this.$emit("updateSpeedSv3", value)
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency
        },
        defaultSpeedObj() {
            return this.speeds.find(obj => {
                return obj.default == 1
            })
        }
    },
    created() {
        this.chosenSpeed = this.defaultSpeedObj.speed
    },
    methods: {
        speedName(speed) {
            switch (speed) {
                case "low":
                    return "Chậm"
                case "normal":
                    return "Trung bình"
                case "medium":
                    return "Thông thường"
                case "high":
                    return "Nhanh"
                default:
                    return speed
            }
        }
    }
}
</script>

<style lang="scss" scoped></style>
